import React from 'react';
import Layout from '../components/Layout/Layout';
import { Result } from 'antd';

class LinksPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="transparent text-center">
          <h1 class="section-title">Links!</h1>
          <p>If you visit any of these, you might notice most websites seem pretty dated or even abandoned.<br /> Many of my favorite websites are passion projects, some of which were started closer to the invention of the web than to today.</p>
        </div>
        <div class="container row mx-auto">
          <div class="column col-lg-8 col-md-12 col-sm-12 mb-3">
            <div class="post shade-me">
              <h1 class="text-center section-title">Birding List Utah</h1>
              <p class="">
                I often find it hard to identify birds (there are number of them) Maybe these links will help. <br />
                My best suggestion: Take a photo and do the identification without the risk of your subject flying far away.
              </p>
              <div class="row">
                <div class="column col-lg-6 col-md-6 col-sm-12">
                  <div class="post shade-me mb-4">
                    <a href="http://www.utahbirds.org/birdsofutah/BirdIndex.htm" class="themeToggler">
                      <h3>Utah Bird List</h3>
                      <p>
                        For a comprehensive list of birds in Utah, check out the Utah birding index.
                      </p>
                    </a>
                  </div>
                  <div class="post shade-me mb-4">
                    <a href="https://jordanrivercommission.com/birds-2/" class="themeToggler">
                      <h3>Jordan River Bird List</h3>
                      <p>
                        If you walk along the Jordan River, there are plenty of birds to see. This Jordan River birds list is really helpful for identification.
                      </p>
                    </a>
                  </div>
                </div>
                <div class="column col-lg-6 col-md-6 col-sm-12">
                  <div class="post shade-me mb-4">
                    <a href="http://www.wasatchaudubon.org/b101_index.htm" class="themeToggler">
                      <h3>Identification Tips</h3>
                      <p>
                        If you just starting birding, Lesson 11's three steps of bird identification is a good place to start.
                      </p>
                    </a>
                  </div>
                  <div class="post shade-me mb-4">
                    <a href="https://www.audubon.org/news/birding-utah" class="themeToggler">
                      <h3>Audubon Bird Hotspots</h3>
                      <p>
                      <ul>
                        <li>
                          Fish Springs National Wildlife Refuge
                        </li>
                        <li>
                          Lytle Ranch
                        </li>
                        <li>
                          Antelope Island State Park
                        </li>
                        <li>
                          Bear River Migratory Bird Refuge
                        </li>
                        <li>
                          Zion National Park
                        </li>
                        <li>
                          Mirror Lake
                        </li>
                        <li>
                          Ouray National Wildlife Refuge
                        </li>
                        <li>
                          Quail Creek Reservoir
                        </li>
                        <li>
                          Big Cottonwood Canyon
                        </li>
                        <li>
                          Tonaquint Nature Center
                        </li>
                        <li>
                          Great Salt Lake Birding Trail
                        </li>
                      </ul>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="column col-lg-4 col-md-6 col-sm-12">
            <div class="post shade-me">
            <h1 class="text-center section-title">Programming</h1>
              <p>
                Mostly for Ruby.  
                <br />
                Some websites that help you improve you programming.  
              </p>
              <div class="post shade-me mb-4">
                <a href="https://www.w3schools.com" class="themeToggler">
                  <h3>W3</h3>
                  <p>
                    I mean... where else?
                  </p>
                </a>
              </div>
              <div class="post shade-me mb-4">
                <a href="https://www.tutorialspoint.com/ruby/ruby_loops.htm" class="themeToggler">
                  <h3>tutorialspoint</h3>
                  <p>
                    This is a great reference for those times you know what you want but not the syntax of how to do it.
                  </p>
                </a>
              </div>
              <div class="post shade-me mb-4">
                <a href="https://www.rubyguides.com/ruby-post-index/" class="themeToggler">
                  <h3>RubyGuides</h3>
                  <p>
                    For helping you learn Ruby these guides are pretty helpful, although the site is pretty persistant in getting you to enter an email address.
                  </p>
                </a>
              </div>
            </div>
          </div> */}
          <div class="column col-lg-4 col-md-6 col-sm-12 mb-3">
            <div class="post shade-me">
            <h1 class="text-center section-title">For Fun</h1>
              <p>
                Just the fun websites I like to visit.
              </p>
              <div class="post shade-me mb-4">
                <a href="http://www.geowayne.com/psbhtml.htm" class="themeToggler">
                  <h3>GeoWayne</h3>
                  <p>
                    Prime Pet Shop Boys information and commentary.
                  </p>
                </a>
                <p>
                  If you're interested, I suggest some of these songs (not nessissarily their best, but Wayne has great commentary on them).
                </p>
                <ul>
                  <li>
                    <a href="http://www.geowayne.com/newDesign/other/imagination.htm" class="themeToggler">In His Imagination</a>
                  </li>
                  <li>
                    <a href="http://www.geowayne.com/newDesign/format/event.htm" class="themeToggler">After the Event</a>
                  </li>
                  <li>
                    <a href="http://www.geowayne.com/newDesign/fundamental/luna.htm" class="themeToggler">Luna Park</a>
                  </li>
                  <li>
                    <a href="http://www.geowayne.com/newDesign/behaviour/boring.htm" class="themeToggler">Being Boring</a>
                  </li>
                  {/* <li>
                    <a href="http://www.geowayne.com/newDesign/introspective/scared.htm" class="themeToggler">I'm Not Scared</a>
                  </li> */}
                  <li>
                    <a href="http://www.geowayne.com/newDesign/release/birthday.htm" class="themeToggler">Birthday Boy</a>
                  </li>
                  {/* <li>
                    <a href="" class="themeToggler"></a>
                  </li> */}
                </ul>
              </div>
              <div class="post shade-me mb-4">
                {/* <h3>Goodreads Read in 2021:</h3>
                <div id="gr_grid_widget_1611816144">
                  Show static html as a placeholder in case js is not enabled - javascript include will override this if things work
                  <div class="gr_grid_container">
                    <div class="gr_grid_book_container"><a title="Thirteen Days: A Memoir of the Cuban Missile Crisis" rel="nofollow" href="https://www.goodreads.com/book/show/342103.Thirteen_Days"><img alt="Thirteen Days: A Memoir of the Cuban Missile Crisis" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1388192123l/342103._SY75_.jpg" /></a>
                    </div>
                    <noscript><br/>Share <a rel="nofollow" href="/">book reviews</a> and ratings with Fox, and even join a <a rel="nofollow" href="/group">book club</a> on Goodreads.</noscript>
                  </div>
                </div>
                <script src="https://www.goodreads.com/review/grid_widget/112993046.Goodreads:%20Read%20in%202021?cover_size=small&hide_link=true&hide_title=true&num_books=200&order=a&shelf=finished-in-2021&sort=date_added&widget_id=1611816144" type="text/javascript" charset="utf-8"></script> */}
              </div>
            </div>
          </div>
        </div>

      </Layout>
    );
  }
}

export default LinksPage;
